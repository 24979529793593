export default {
  signIn: {
    en: 'Or log in with one of these platforms:',
  },
  signUp: {
    en: 'OR',
  },
  signUpLong: {
    en: 'Or quickly sign up',
  },
  cookiesErrorMessage: {
    en: `
      ERROR: Your current browser mode blocks third-party data or does not support it.
      You must have third-party cookies <strong>enabled</strong> to sign in with Google.
    `,
  },
}
