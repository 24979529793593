import React, { useCallback } from 'react'
import links from 'links'
import { track } from 'analytics'
import { useHistory, useSearch } from 'router'
import { processError } from 'helpers'
import { useGlobalLoader } from 'hooks'
import { useFacebookAuth } from 'modules/facebook'
import { useFacebookSignIn } from 'modules/user'
import type { FacebookSigninErrorCode } from 'typings/graphql'
import { useAuthPage } from 'containers/AuthPageProvider/AuthPageProvider'

import SocialButton from '../SocialButton/SocialButton'
import messages from './messages'


type FacebookButtonProps = {
  className?: string
  isSignUp?: boolean
  isRound?: boolean
  withRedirect?: boolean
}

const FacebookButton: React.FunctionComponent<FacebookButtonProps> = (props) => {
  const { className, isSignUp, isRound, withRedirect = true } = props

  // ATTN we don't need to check the current status, because we should re-login a user to fetch fresh tokens
  const { isReady, isFetching: isFacebookAuthFetching, login } = useFacebookAuth({ skipLoginStatus: true })
  const [ signIn, { isFetching: isFacebookSignInFetching } ] = useFacebookSignIn()
  const { flow, onRedirect } = useAuthPage()

  const isFetching = isFacebookAuthFetching || isFacebookSignInFetching

  const { showLoader, hideLoader } = useGlobalLoader()
  const history = useHistory()
  const search = useSearch()

  const handleClick = useCallback(async () => {
    try {
      const socialNetwork = 'Facebook'

      track(isSignUp ? 'Signup with social networks click' : 'Login with social networks click', {
        socialNetwork,
      })

      showLoader()

      const { accessToken, expiresIn } = await login()

      const { isNewUser } = await signIn({
        accessToken,
        expiresIn,
      })

      if (isNewUser) {
        track('Signup success', {
          flow,
          socialNetwork,
        })
      }

      if (withRedirect) {
        await onRedirect()
      }
    }
    catch (error) {
      const facebookErrorCode: FacebookSigninErrorCode = error?.cause?.facebookErrorCode

      if (facebookErrorCode === 'EMAIL_WAS_NOT_PROVIDED' || facebookErrorCode === 'FACEBOOK_NOT_UNIQUE_EMAIL') {
        history.push(`${links.facebookEmail}${search}`)
        return
      }
      else if (facebookErrorCode === 'USER_SHOULD_RESET_PASSWORD') {
        history.push(links.recoverPassword)
        return
      }

      track('Signup fail', {
        reason: error.message,
      })

      processError(error)
    }
    finally {
      hideLoader()
    }
  }, [ isSignUp, showLoader, login, signIn, withRedirect, flow, onRedirect, history, search, hideLoader ])

  return (
    <SocialButton
      className={className}
      icon="40/facebook-in-circle"
      title={isSignUp ? messages.signUp : messages.signIn}
      isRound={isRound}
      disabled={!isReady}
      loading={isFetching}
      onClick={handleClick}
      data-testid="facebookSignInButton"
    />
  )
}


export default FacebookButton
