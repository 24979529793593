import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'


const AuthPageContainer: React.CFC<{ className?: string }> = ({ className, children }) => {
  const { isMobile } = useDevice()

  return (
    <div className={cx('py-40', className)}>
      <div className={cx(`relative mx-auto`, isMobile ? 'px-16' : 'border-solid-gray-30 max-w-464 bg-white p-40')}>
        {children}
      </div>
    </div>
  )
}


export default AuthPageContainer
