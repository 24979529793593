import { useCallback, useEffect, useRef } from 'react'
import { encodeBase64 } from 'crypto-helpers'
import { useHistory, useSearchParams } from 'router'
import cx from 'classnames'

import { CausedError, processError } from 'helpers'
import { track } from 'analytics'
import config from 'config'
import links from 'links'

import { useGlobalLoader } from 'hooks'
import { useLocalePreferences } from 'modules/localePreferences'
import { type UseGoogleAuthProps, useGoogleISInitialize } from 'modules/google'
import { useGoogleSignIn } from 'modules/user'

import { type GoogleSignInErrorCode } from 'typings/graphql'
import { useAuthPage } from 'containers/AuthPageProvider/AuthPageProvider'

import SocialButton from '../SocialButton/SocialButton'
import messages from './messages'


type GoogleButtonProps = {
  className?: string
  isSignUp?: boolean
  isRound?: boolean
  withRedirect?: boolean
  onAuthError?: UseGoogleAuthProps['onError']
}


const socialNetwork = 'Google'
/**
 * Google Identity Services library
 */
const GoogleButton: React.FunctionComponent<GoogleButtonProps> = (props) => {
  const { className, isSignUp, isRound, withRedirect = true, onAuthError } = props

  const { localePreferences } = useLocalePreferences()
  const [ signIn ] = useGoogleSignIn()
  const { flow, onRedirect } = useAuthPage()
  const { showLoader, hideLoader } = useGlobalLoader()
  const [ { socialButtonData } ] = useSearchParams()
  const history = useHistory()

  const btnContainerRef = useRef<HTMLDivElement>(null)
  const isTestButtonEnabled = Boolean(socialButtonData) && config.runEnv !== 'prod'

  const handleSuccess = useCallback(async (data) => {
    try {
      track(isSignUp ? 'Signup with social networks click' : 'Login with social networks click', {
        socialNetwork,
      })

      showLoader()

      let params: Parameters<typeof signIn>[0]

      if (isTestButtonEnabled) {
        // ATTN this is required for json validation
        // id, firstName, lastName, email, gender are required as strings
        const data = JSON.stringify(JSON.parse(socialButtonData))

        params = {
          idToken: `test-${encodeBase64(data)}`,
        }
      }
      else {
        params = {
          idToken: data.credential,
        }
      }

      const { isNewUser } = await signIn(params)

      if (isNewUser) {
        track('Signup success', {
          flow,
          socialNetwork,
        })
      }

      if (withRedirect) {
        await onRedirect()
      }
    }
    catch (error) {
      const googleErrorCode: GoogleSignInErrorCode = error?.cause?.googleErrorCode

      if (googleErrorCode === 'USER_SHOULD_RESET_PASSWORD') {
        history.push(links.recoverPassword)
        return
      }

      track('Signup fail', {
        reason: error.message,
      })

      processError(error)
    }
    finally {
      hideLoader()
    }
  }, [ isSignUp, showLoader, hideLoader, socialButtonData, signIn, onRedirect, flow, withRedirect, history, isTestButtonEnabled ])

  const { isScriptLoaded, loadingError } = useGoogleISInitialize({
    // TODO replace it on initCodeClient method when BE will be support only auth code
    method: 'initialize',
    onSuccess: handleSuccess,
  })

  useEffect(() => {
    if (loadingError) {
      if (typeof onAuthError === 'function') {
        const error = new CausedError(loadingError)
        // TODO support CookiesError - added on 2/03/23 by vkudla
        onAuthError({ error, isCookiesError: false })
      }

      return
    }

    if (!isScriptLoaded || isTestButtonEnabled) {
      return
    }

    window?.google?.accounts?.id?.renderButton(btnContainerRef.current, {
      type: isRound ? 'icon' : 'standard',
      theme: 'outline',
      size: 'large',
      text: isSignUp ? 'signup_with' : 'signin_with',
      shape: isRound ? 'circle' : 'rectangular',
      logo_alignment: 'left',
      width: btnContainerRef.current.clientWidth,
      locale: localePreferences.locale,
    })

    if (isRound) {
      const updateFrameScale = () => {
        const frame = btnContainerRef.current?.querySelector('iframe')
        if (frame) {
          frame.style.transform = `scale(${btnContainerRef.current.clientHeight / 40})`
        }
      }

      updateFrameScale()
      window.addEventListener('resize', updateFrameScale, { passive: true })

      return () => {
        window.removeEventListener('resize', updateFrameScale)
      }
    }
  }, [ isScriptLoaded, onAuthError, isSignUp, isRound, loadingError, isTestButtonEnabled, localePreferences.locale ])

  useEffect(() => {

  }, [])

  if (isTestButtonEnabled) {
    // TODO Will be uncommented when we move on to initCodeClient flow
    return (
      <SocialButton
        className={className}
        icon="40/google-in-circle"
        title={isSignUp ? messages.signUp : messages.signIn}
        isRound={isRound}
        loading={!isScriptLoaded}
        disabled={!isScriptLoaded}
        onClick={handleSuccess}
        data-testid="googleSignInButton"
      />
    )
  }

  return (
    <div
      className={cx(
        className,
        isRound ? 'size-s64 flex items-center justify-center' : 'h-[44px]'
      )}
      ref={btnContainerRef}
      data-testid="googleSignInButton"
    />
  )
}


export default GoogleButton
