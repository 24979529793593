import React, { useCallback } from 'react'
import { processError } from 'helpers'
import { useHistory } from 'router'
import links from 'links'
import { track } from 'analytics'
import { useGlobalLoader } from 'hooks'

import { useAppleAuth } from 'modules/apple'
import { useAppleSignIn } from 'modules/user'
import type { AppleSignInErrorCode } from 'typings/graphql'
import { useAuthPage } from 'containers/AuthPageProvider/AuthPageProvider'

import SocialButton from '../SocialButton/SocialButton'
import messages from './messages'


type AppleButtonProps = {
  className?: string
  isSignUp?: boolean
  isRound?: boolean
  withRedirect?: boolean
}

const AppleButton: React.FunctionComponent<AppleButtonProps> = (props) => {
  const { className, isSignUp, isRound, withRedirect = true } = props

  const { isReady, isFetching: isAppleAuthFetching, login } = useAppleAuth()
  const [ signIn, { isFetching: isAppleSignInFetching } ] = useAppleSignIn()
  const { flow, onRedirect } = useAuthPage()
  const history = useHistory()
  const { showLoader, hideLoader } = useGlobalLoader()

  const isFetching = isAppleAuthFetching || isAppleSignInFetching

  const handleClick = useCallback(async () => {
    try {
      showLoader()

      const socialNetwork = 'Apple'

      track(isSignUp ? 'Signup with social networks click' : 'Login with social networks click', {
        socialNetwork,
      })

      const { idToken, code, state, email, firstName, lastName } = await login()

      const { isNewUser } = await signIn({
        idToken,
        code,
        state,
        email,
        firstName,
        lastName,
      })

      if (isNewUser) {
        track('Signup success', {
          flow,
          socialNetwork,
        })
      }

      if (withRedirect) {
        await onRedirect()
      }
    }
    catch (error) {
      const appleErrorCode: AppleSignInErrorCode = error?.cause?.appleErrorCode

      if (appleErrorCode === 'USER_SHOULD_RESET_PASSWORD') {
        history.push(links.recoverPassword)
        return
      }

      track('Signup fail', {
        reason: error.message,
      })

      processError(error)
    }
    finally {
      hideLoader()
    }
  }, [ showLoader, isSignUp, login, signIn, withRedirect, flow, onRedirect, history, hideLoader ])

  return (
    <SocialButton
      className={className}
      icon="40/apple-pay"
      title={isSignUp ? messages.signUp : messages.signIn}
      disabled={!isReady}
      isRound={isRound}
      loading={isFetching}
      onClick={handleClick}
      data-testid="appleSignInButton"
    />
  )
}


export default AppleButton
