import React from 'react'
import cx from 'classnames'

import { Text } from 'components/dataDisplay'


type ErrorMessageProps = {
  className?: string
  text: string | Intl.Message
  'data-testid'?: string
}

const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = (props) => {
  const { className, text, 'data-testid': dataTestId } = props

  return (
    <Text
      className={cx(className, 'border-solid-gray-30 bg-white px-16 py-20 text-center')}
      message={text}
      withInnerLinksStyling={false}
      style="p4"
      color="black"
      html
      data-testid={dataTestId}
    />
  )
}


export default ErrorMessage
