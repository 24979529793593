import React, { useCallback, useState } from 'react'
import cx from 'classnames'
import { type UseGoogleAuthProps } from 'modules/google'

import { Text, type TextStyle } from 'components/dataDisplay'

import FacebookButton from './components/FacebookButton/FacebookButton'
import GoogleButton from './components/GoogleButton/GoogleButton'
import AppleButton from './components/AppleButton/AppleButton'
import ErrorMessage from '../ErrorMessage/ErrorMessage'

import messages from './messages'


type SocialButtonsProps = {
  className?: string
  isSignUp?: boolean
  titleClassName?: string
  titleStyle?: TextStyle
  titleMessage?: Intl.Message | string
  withLongSignUpTitle?: boolean
  withRoundButtons?: boolean
  withRedirect?: boolean
}

const SocialButtons: React.FunctionComponent<SocialButtonsProps> = (props) => {
  const {
    className,
    isSignUp,
    titleClassName,
    titleStyle = 'p2',
    titleMessage,
    withLongSignUpTitle,
    withRoundButtons,
    withRedirect = true,
  } = props

  const [ isCookiesError, setIsCookiesError ] = useState(false)

  const handleGoogleAuthError = useCallback<UseGoogleAuthProps['onError']>(
    ({ isCookiesError }) => {
      setIsCookiesError(isCookiesError)
    }, [])

  return (
    <div className={cx(className, withRoundButtons && 'text-center')}>
      {
        isSignUp ? (
          <Text
            className={titleClassName}
            message={titleMessage || (withLongSignUpTitle ? messages.signUpLong : messages.signUp)}
            style={titleStyle}
            tag="h2"
            align="center"
          />
        ) : (
          <Text
            className={titleClassName}
            message={titleMessage || messages.signIn}
            style={titleStyle}
          />
        )
      }
      {
        isCookiesError && (
          <ErrorMessage
            className="mt-16"
            text={messages.cookiesErrorMessage}
          />
        )
      }
      <div className={withRoundButtons ? 'mx-auto flex items-center justify-center gap-16' : null}>
        <GoogleButton
          className={cx('mt-16', withRoundButtons && 'size-s64')}
          isSignUp={isSignUp}
          isRound={withRoundButtons}
          withRedirect={withRedirect}
          onAuthError={handleGoogleAuthError}
        />
        <FacebookButton
          className="mt-16"
          isSignUp={isSignUp}
          isRound={withRoundButtons}
          withRedirect={withRedirect}
        />
        <AppleButton
          className="mt-16"
          isSignUp={isSignUp}
          isRound={withRoundButtons}
          withRedirect={withRedirect}
        />
      </div>
    </div>
  )
}


export default SocialButtons
