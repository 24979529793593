import React from 'react'
import cx from 'classnames'
import { Message } from 'intl'

import { Icon, type IconName } from 'components/dataDisplay'
import { RoundButton, ButtonBase, type ButtonBaseProps } from 'components/inputs'


type SocialButtonProps = Pick<ButtonBaseProps, 'className' | 'onClick' | 'loading' | 'disabled' | 'data-testid'> & {
  icon: IconName
  title: Intl.Message | string
  isRound?: boolean
}

const SocialButton: React.FunctionComponent<SocialButtonProps> = (props) => {
  const { className, icon, title, loading, isRound, disabled, onClick, 'data-testid': dataTestId } = props

  if (isRound) {
    return (
      <RoundButton
        className={cx(className, 'border-solid-gray-30 desktop-hover:border-gold-30 bg-white')}
        title={title}
        size={64}
        loading={loading}
        disabled={disabled}
        onClick={onClick}
        data-testid={dataTestId}
      >
        <Icon name={icon} />
      </RoundButton>
    )
  }

  // 44px height is dictated by google button
  const rootClassName = cx(
    'border-solid-gray-30 text-p2 relative h-[44px] w-full select-none rounded bg-white transition-all duration-200 ease-out',
    {
      'cursor-pointer hover:duration-0 focus:duration-0': !loading && !disabled,
      'text-gray-50 grayscale': loading || disabled,
      'cursor-not-allowed': disabled,
      'cursor-wait': loading,
    },
    className
  )

  return (
    <ButtonBase
      className={rootClassName}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <Icon
        className="absolute left-[2px] top-1/2 -translate-y-1/2 scale-[0.85]"
        name={icon}
      />
      <Message value={title} />
    </ButtonBase>
  )
}


export default SocialButton
